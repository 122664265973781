<template>
  <div class="container">
    <div class="head_container">
      <el-image
        class="head_img"
        :src="require('@as/serve/knowledge/banner.png')"
      ></el-image>
      <div class="head_main">
        <div class="head_title">2021考研专业课名师全程班</div>
        <div class="head_subtitle">
          <span>3805人学过</span><span>讲师：孙欣然</span>
        </div>
        <div class="head_subtitle">更新时间：2020年9月14日</div>
        <div class="head_price">¥ 200.00</div>
        <div class="head_btn">立即参加</div>
      </div>
      <div class="head_buoy">
        <el-image
          class="buoy_icon"
          :src="require('@as/serve/knowledge/icon_4.png')"
        ></el-image>
        <el-image
          class="buoy_icon"
          :src="require('@as/serve/knowledge/icon_5.png')"
        ></el-image>
      </div>
    </div>
    <div class="main">
      <div class="main_left">
        <div class="section">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">课程简介</el-menu-item>
            <el-menu-item index="2">课程目录</el-menu-item>
            <el-menu-item index="3">课程评论</el-menu-item>
          </el-menu>
          <template v-if="activeIndex == 1">
            <div class="section_content">简介内容</div>
          </template>
          <template v-if="activeIndex == 2">
            <div class="section_content">
              <div class="row" v-for="item in 3" v-bind:key="item">
                <el-image
                  class="row_icon"
                  :src="require('@as/serve/knowledge/icon_6.png')"
                ></el-image>
                <div class="row_title">实证有效的干预方法有哪些？</div>
                <div class="row_time">（15分钟）</div>
                <div class="row_btn">免费试学</div>
              </div>
            </div>
          </template>
          <template v-if="activeIndex == 3">
            <div class="section_content">
              <div class="top">
                <div class="left">
                  <div class="num">8</div>
                  <div>条评论</div>
                </div>
                <div class="right">
                  <el-image
                    class="icon"
                    :src="require('@as/serve/knowledge/icon_10.png')"
                  ></el-image>
                  <div>我来说两句</div>
                </div>
              </div>
              <el-input
                type="textarea"
                placeholder="说点什么吧……"
                v-model="textarea"
              >
              </el-input>
              <div class="comment_btn">评论</div>
              <div class="list">
                <div class="list_section" v-for="item in 3" v-bind:key="item">
                  <el-image
                    class="list_icon"
                    :src="require('@as/serve/knowledge/icon_5.png')"
                  ></el-image>
                  <div class="info">
                    <div class="title">
                      <div class="name">大人物</div>
                      <div class="time">1天前</div>
                    </div>
                    <div class="detail">
                      <div class="text">感觉每年的设计趋势都有那几个大条～</div>
                      <div class="reply_btn">
                        <el-image
                          class="icon"
                          :src="require('@as/serve/knowledge/icon_9.png')"
                        ></el-image>
                        <span>回复</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pull_more">加载更多</div>
            </div>
          </template>
        </div>
      </div>
      <div class="main_right">
        <div class="section">
          <div class="head">
            <div class="title">
              <el-image
                class="title_icon"
                :src="require('@as/serve/knowledge/icon_8.png')"
              ></el-image>
              <div>推荐课程</div>
            </div>
            <div class="title_more">MORE</div>
          </div>
          <div class="row" v-for="item in 3" v-bind:key="item">
            <el-image
              class="img"
              :src="require('@as/serve/knowledge/banner.png')"
            ></el-image>
            <div class="info">
              <div class="title">保险资金运用合规管理及争议解决热点</div>
              <div class="subtitle">陈俊</div>
              <div class="subtitle">2485人参加</div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="head">
            <div class="title">
              <el-image
                class="title_icon"
                :src="require('@as/serve/knowledge/icon_7.png')"
              ></el-image>
              <div>相关咨询</div>
            </div>
            <div class="title_more">MORE</div>
          </div>
          <div class="row last" v-for="item in 3" v-bind:key="item">
            <el-image
              class="img"
              :src="require('@as/serve/knowledge/banner.png')"
            ></el-image>
            <div class="info">
              <div class="title">媒体合作咨询</div>
              <div class="subtitle">姓名：陈俊</div>
              <div class="subtitle">电话：2485</div>
              <div class="subtitle">邮箱：2485@163.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      this.activeIndex = keyPath
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  background: #f5f7f8;

  .head_container {
    margin: 0 auto;
    padding: 20px 25px;
    display: flex;
    align-items: flex-start;
    width: 1200px;
    height: 350px;
    background: #ffffff;
    box-sizing: border-box;
    .head_img {
      flex-shrink: 0;
      width: 260px;
      height: 310px;
    }
    .head_main {
      flex: 1;
      margin: 10px 0 0 66px;
      .head_title {
        margin-bottom: 26px;
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .head_subtitle {
        margin-bottom: 16px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
        span {
          margin-right: 29px;
        }
      }
      .head_price {
        margin-top: 43px;
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ea534c;
      }
      .head_btn {
        margin-top: 56px;
        width: 200px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        background: #4697c5;
        border-radius: 2px;
        cursor: pointer;
      }
    }
    .head_buoy {
      display: flex;
      .buoy_icon {
        padding: 10px 20px;
        border: 1px solid #cccccc;
        cursor: pointer;
        &:last-child {
          border-left-width: 0;
        }
        & /deep/ .el-image__inner {
          width: 22px;
          height: 21px;
        }
      }
    }
  }
  .main {
    margin: 20px auto 0;
    display: flex;
    width: 1200px;
    .main_left {
      flex: 1;
      & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid #4c9bbb;
      }
      & /deep/ .el-menu--horizontal > .el-menu-item {
        color: #595757;
      }
      & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
        color: #4c9bbb;
      }

      .section {
        margin-bottom: 20px;
        .section_content {
          padding: 30px;
          background: #fff;
          .row {
            padding: 14px 20px;
            display: flex;
            align-items: center;
            background: #f5f6fa;
            cursor: pointer;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            .row_icon {
              width: 28px;
              height: 22px;
            }
            .row_title {
              margin: 0 13px 0 18px;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #666666;
            }
            .row_time {
              font-size: 14px;
              color: #999;
            }
            .row_btn {
              font-size: 14px;
              color: #579ed5;
            }
          }
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              display: flex;
              align-items: center;
              font-size: 18px;
              color: #000000;
              .num {
                margin-right: 6px;
                font-size: 18px;
                color: #4b99af;
              }
            }
            .right {
              display: flex;
              align-items: center;
              font-size: 16px;
              color: #999999;
              .icon {
                margin-right: 6px;
                width: 18px;
                height: 18px;
              }
            }
          }
          & /deep/ .el-textarea__inner {
            padding: 0 10px;
            margin: 24px 0 18px;
            font-size: 14px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 50px;
            background: #f7f7f7;
            border: 1px solid #ededed;
            border-radius: 4px;
            box-sizing: border-box;
          }
          .comment_btn {
            margin-left: auto;
            width: 80px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            background: #4798c6;
            border-radius: 4px;
            cursor: pointer;
          }
          .list {
            margin-top: 25px;
            border-top: 1px solid #eee;
            .list_section {
              padding: 20px 0;
              display: flex;
              border-bottom: 1px solid #eee;
              .list_icon {
                margin-right: 20px;
                width: 40px;
                height: 40px;
              }
              .info {
                flex: 1;
                .title {
                  display: flex;
                  align-items: center;
                  .name {
                    font-size: 14px;
                    font-family: PingFangSC;
                    font-weight: 400;
                    color: #333333;
                  }
                  .time {
                    margin-left: 19px;
                    font-size: 12px;
                    font-family: PingFangSC;
                    font-weight: 400;
                    color: #999999;
                  }
                }
                .detail {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 10px;
                  width: 100%;
                  font-size: 14px;
                  font-family: PingFangSC;
                  font-weight: 400;
                  color: #666666;
                  .text {
                    flex: 1;
                  }
                  .reply_btn {
                    margin-top: auto;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-family: SourceHanSansCN;
                    font-weight: 400;
                    color: #9c9c9c;
                    cursor: pointer;
                    .icon {
                      margin-right: 6px;
                      width: 14px;
                      height: 13px;
                    }
                  }
                }
              }
            }
          }
          .pull_more {
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #4b98ae;
            text-align: center;
            background: #f1f7f9;
            cursor: pointer;
          }
        }
      }
    }
    .main_right {
      margin-left: 20px;
      width: 330px;
      .section {
        padding: 23px 25px;
        margin-bottom: 20px;
        background: #fff;
        .head {
          display: flex;
          justify-content: space-between;
          .title {
            padding-bottom: 6px;

            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            border-bottom: 2px solid #4a97ad;
            .title_icon {
              margin-right: 5px;
              width: 22px;
              height: 20px;
            }
          }
          .title_more {
            padding-bottom: 6px;
            flex: 1;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #a6a7a7;
            text-align: right;
            border-bottom: 1px solid #dedede;
            cursor: pointer;
          }
        }
        .row {
          padding: 17px 6px;
          display: flex;
          align-items: center;
          &:hover {
            box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.1);
          }
          &:not(:last-child) {
            border-bottom: 1px solid #dedede;
          }
          &.last {
            align-items: flex-start;
            .img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
            }
            .info {
              margin-top: 10px;
            }
          }
          .img {
            margin-right: 15px;
            flex-shrink: 0;
            width: 78px;
            height: 93px;
          }
          .info {
            .title {
              margin-bottom: 4px;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #393939;
              line-height: 20px;
            }
            .subtitle {
              margin-top: 10px;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
    }
  }
}
</style>